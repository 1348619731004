export const getLightModeStyle = (elementType, invert) => {
    var className = '';
    var lightMode = localStorage.getItem('lightMode') === 'true';
    if(elementType === 'p') {
        if (!invert){
            className = lightMode ? "textColor" : "textColor darkMode";
        }
        else {
            className = lightMode ? "textColor darkMode" : "textColor";
        }
    }
    else if (elementType === 'container') {
        if (!invert) {
            className = lightMode ? 'pageColor' : 'pageColor darkMode'
        }
        else {
            className = lightMode ? 'pageColor darkMode' : 'pageColor' 
        }
    }
    return className
}

export const getLightMode = () => {
    return localStorage.getItem('lightMode') === 'true'
}

export const getTextItem = (invert, element, id, style, content) => {
    var returner = null
    if (element === 'p') {
        returner = <p id={id} className={getLightModeStyle('p', invert)} style={style}>{content}</p>
    }
    else if (element === 'h1') {
        returner = <h1 id={id} className={getLightModeStyle('p', invert)} style={style}>{content}</h1>
    }
    else if (element === 'h2') {
        returner = <h2 id={id} className={getLightModeStyle('p', invert)} style={style}>{content}</h2>
    }
    else if (element === 'h3') {
        returner = <h3 id={id} className={getLightModeStyle('p', invert)} style={style}>{content}</h3>
    }
    else if (element === 'h4') {
        returner = <h4 id={id} className={getLightModeStyle('p', invert)} style={style}>{content}</h4>
    }
    else if (element === 'h5') {
        returner = <h5 id={id} className={getLightModeStyle('p', invert)} style={style}>{content}</h5>
    }
    else if (element === 'h6') {
        returner = <h6 id={id} className={getLightModeStyle('p')} style={style}>{content}</h6>
    }
    else {
        returner = <h1>ELEMENT TYPE NOT IMPLEMENTED YET</h1>
    }
    return returner;
}

export const getLinkItem = (invert, id, href, style, content) => {
    var returner = <a id={id} className={getLightModeStyle('p', invert)} href={href} style={style}>{content}</a>
    return returner;
}

export const getTitle = () => {
    var lightMode = localStorage.getItem('lightMode') === 'true';
    return lightMode ? 'radiate' : 'decay'
}