// CSS
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle'; // This fixed the issue with dropdowns?
//import '../styles.css';
// assets
import inf from '../assets/infinity.wav'
import qp from '../assets/qp.wav'
import wobble from '../assets/wobble.gif'
import peer from '../assets/watchingMe.PNG'
import atom from '../assets/atom-symbol.gif'
import sheLurks from '../assets/sheLurks2Trans.png'
import trans from '../assets/trans.png'
import worldLooking from '../assets/goodGlobe.gif'
import worldGrid from '../assets/globe-grid.gif'
import ogGlobe from '../assets/worldLookingTrans.png'
import transGlobe from '../assets/meltyGlobe.gif'
import explode from '../assets/pop.gif'
// Packages
import {React, useState, useEffect } from 'react'
import {getLightModeStyle, getTextItem, getLinkItem, getTitle} from '../functions.js'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// Components
import Navbar from '../Components/Navbar';
import MiniHeader from '../Components/MiniHeader.js';

const onSubmit = (event, navigate, setCookie, deviceID, alias, password) => {
    event.preventDefault()
    axios.get('http://localhost:9000/signin', { params: { deviceID: deviceID, alias: alias, password: password }})
    .then((res) => {
        console.log(res)
        if(res.data && res.data.alias != undefined) {
            setCookie('alias', res.data.alias)
            alert(`welcome ${res.data.alias}`)
            navigate("/")
        }
        else {
            alert(res.data)
        }
    })
    .catch((err) => {
        console.log(err)
        alert(err)
    })
}

const Login = () => {
    const [cookies, setCookie, removeCookie] = useCookies([]);
    const [lightMode, setLightMode] = useState(localStorage.getItem('lightMode')=='true')
    const [alias, setAlias] = useState('')
    const [key, setKey] = useState('')
    const navigate = useNavigate();

    return (
        <div className={`container page-container ${getLightModeStyle('container')}`}>
            <Navbar onToggleLightMode={(value) => {
                    setLightMode(value)
                }}
            />
            <MiniHeader/>
            <div className='container d-flex flex-column' style={{height:"100%", width:'50%', paddingTop:"10%"}}>
                <div className='container d-flex justify-content-center' style={styles.spacerTop}>
                    <>{getTextItem(false, 'h6', 'joinUsAliasLable', {width:'100px'}, "alias:")}</>
                    <input type='text' style={styles.input} onChange={(event) => {
                        setAlias(event.target.value)
                    }}/> 
                </div>
                <div className='container d-flex justify-content-center' style={styles.spacerTop}>
                    <>{getTextItem(false, 'h6', 'joinUsKeyLable', {width:'100px'}, "key:")}</>
                    <input type='text' style={styles.input} onChange={(event) => {
                        setKey(event.target.value)
                    }}/> 
                </div>
                <div className='container d-flex justify-content-center'>
                    <button 
                    className={(lightMode ? 'btn textColor' : 'btn textColor darkMode')} 
                    id='joinUsSubmitBtn'
                    onClick={(event)=> {
                        onSubmit(event, navigate, setCookie, cookies['deviceID'], alias, key)
                    }}>enter</button>
                </div>
            </div>
        </div>
    )
}

const styles = {
    input: {
        flex:'1'
    },
    spacerTop: {
        paddingTop:'1%'
    }
}

export default Login