// CSS
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle'; // This fixed the issue with dropdowns?
import '../styles.css';
// assets
import inf from '../assets/infinity.wav'
import qp from '../assets/qp.wav'
import wobble from '../assets/wobble.gif'
import peer from '../assets/watchingMe.PNG'
import atom from '../assets/atom-symbol.gif'
import sheLurks from '../assets/sheLurks2Trans.png'
import trans from '../assets/trans.png'
import worldLooking from '../assets/goodGlobe.gif'
import worldGrid from '../assets/globe-grid.gif'
import ogGlobe from '../assets/worldLookingTrans.png'
import transGlobe from '../assets/meltyGlobe.gif'
import explode from '../assets/pop.gif'
// Packages
import {React, useState, useEffect} from 'react'
import { useCookies } from 'react-cookie';
import {getLightModeStyle, getTextItem, getLinkItem, getTitle} from '../functions.js'
// Components
import Navbar from '../Components/Navbar';
import MiniHeader from '../Components/MiniHeader.js'
import { fireEvent } from '@testing-library/react';
import NumbersCarosel from '../Components/numbersCarosel.js';

const restartMusic = () => {
    var audio = document.getElementById('backgroundMusic')
    audio.currentTime=0
    audio.play=true
}

const shuffleMusic = (list) => {
    var audio = document.getElementById('backgroundMusic')
    audio.pause = true
    var index = Math.floor(list.length * Math.random())
    console.log(index)
    audio.src=list[index]
    audio.currentTime=0
    audio.play = true
}
const getBlogGif = (lightMode) => {
    return (lightMode ? worldLooking : worldGrid)
}
const getLeftContainerPillContents = (lightMode) => {
    if (lightMode) {
        return (
            <div className='row' style={{position:'relative', left:'-25%', bottom:'-70%'}}>
                {
                    //getTextItem(true, 'p','dayManText',{},'___________________________')
                    <></>
                }
            </div>
        )
    }
    else {
        return (
            <div className='row justify-content-start'>
                <div className='col-sm align-content-start'>
                    <div className='row justify-content-start'>
                        <img src={sheLurks} width='100px' height='100px' style={{position:'relative', left:'0%'}}></img>
                    </div>
                </div>
            </div>
        )
    }
}

const getRightContainerPillContents = (lightMode) => {
    if (lightMode) {
        return (
            <div className='row justify-content-end'>
                <div className='col-sm align-content-end'>
                    <div className='row justify-content-end'>
                        <img src={peer} width='100px' height='100px' style={styles.containerRightPillImage}></img>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className='row' style={{ width: '150px', position:'relative', left:'65%', bottom:'-70%'}}>
                {
                    //getTextItem(true, 'p','dayManText',{},'...stay awake')
                    <></>
                }
            </div>
        )
    }
}
// { <button className="btn btn-primary" title='shutup' onClick={()=>{
//                                 setMute(!mute)
//                                 restartMusic()
//                             }}/> }
// <button className="btn btn-primary" title='shuffle' onClick={()=>{
//     shuffleMusic(music)
// }}/>
{/* <audio id='backgroundMusic' src={music[nowPlaying]} autoPlay={true} muted={mute} onEnded={()=>{
                    setNowPlaying((nowPlaying+1)%2)
                    console.log(`now playing ${nowPlaying}`)
                    setUpdate(true)
                }} loop="1"/> */}

let potentialContent = "You are an individual. You are the sum of all your actions and experiences leading up to this very moment. Because of that, you may have associations of your own. Maybe the color yellow always makes you happy, maybe the moon reminds you of a loved one, maybe the sound of metal pipes hitting the ground makes you laugh. Whatever it may be, you attach meaning or feeling to it. That thing might mean something completely different to someone else, or may not mean anything at all! It is all about your perspective on reality. Your reasoning or story is (or should be) private to you, and those whom you choose to let in. This reasoning, this explaination for your perception, is \"context\". Pairing these impactful ideas, or objects with the framework detailed in \"ORDER, QUANTITY, NUMBER\" gives the context for my perception of reality. Or atleast gives you one of the lenses through which I perceive reality, it's never really so black and white."
let reactionContent = "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of \"de Finibus Bonorum et Malorum\" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, \"Lorem ipsum dolor sit amet..\", comes from a line in section 1.10.32."
let about = "Decay to Radiate is a passion project from a schizophrenic software developer with over 5 years experience with delusions. D2R is a glimpse into a mind plagued with inbalance. Besides hearing voices and having beliefs rooted in things outside reality, I am a normal person who doesn't stand out in a crowd. I am lucky. D2R is an attempt to connect with others on a level of intimacy that is hard to achieve in the real world. D2R is a place where there is no judgement and where ideas and perspectives can be shared and conversations can flower. If you or a loved one has experience with extreme states of mind, D2R welcomes you and personally wants to express sympathy for the pain and stress that mental illness causes for friends and family alike."
let moto = "You are not alone, you are loved, and you matter."

const Home = () => {
    const [cookies, setCookie, removeCookie] = useCookies([]);
    const [lightMode, setLightMode] = useState(localStorage.getItem('lightMode')=='true')
    const [mute, setMute] = useState(false)
    const [nowPlaying, setNowPlaying] = useState(0)
    const [update, setUpdate] = useState(false)
    let music = [inf, qp]

    if(cookies['deviceID'] === undefined) {
        // New First time visitor
        setCookie('deviceID', crypto.randomUUID())
    }

    useEffect(() => {
        if(update) {
            setUpdate(false)
        }
    },[])
    return (
        <div className={`container page-container ${getLightModeStyle('container')}`}>
            <Navbar onToggleLightMode={(value) => {
                setLightMode(value)
            }}/>
            <MiniHeader/>
            <div className='container'>
                <div id='homePage3ColContentContainer' className='container'>
                    <div className='row' style={styles.rowCenter}>
                        <div className='col-sm align-self-start content-left-container'>
                            <div id='homeLeftColTitle' className='row'>
                                <img src={(lightMode ? atom : transGlobe)} width='100px' height='250px'></img>
                                <>{getTextItem(false,  'h4', 'homeLeftColTitleText', {}, 'INSIGHT')}</>
                            </div>
                            <div id='homeLeftColContent' className='row'>
                                <>{getTextItem(false,  'p', 'homeLeftColContentText', {}, potentialContent)}</>
                            </div>
                        </div>
                        <div className='col-sm align-self-start content-center-container'>
                            <div id='homeMiddleColTitle' className='row'>
                                <img src={(lightMode ? wobble : transGlobe)} width='100px' height='250px'></img>
                                <>{getTextItem(false,  'h4', 'homeMiddleColTitleText', {}, 'COMMUNITY')}</>
                            </div>
                            <div id='homeMiddleColContent' className='row'>
                                <>{getTextItem(false,  'p', 'homeMiddleColContentText', {}, 'hello World from the middle')}</>
                            </div>
                        </div>
                        <div className='col-sm align-self-start content-right-container d-flex flex-column flex' style={{height:'100%'}}>
                            <div id='homeRightColTitle' className='row'>
                                <img src={(lightMode ? explode : transGlobe)} width='100px' height='250px' style={{borderRadius:'25px'}}></img>
                                <>{getTextItem(false,  'h4', 'homeRightColTitleText', {}, 'ORDER, QUANTITY, NUMBER')}</>
                            </div>
                            <div id='homeRightColContent' className='row' style={{height:'100%'}}>
                                <NumbersCarosel/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row' style={styles.rowCenter}>
                        <div className={`col-sm ${lightMode ? 'pageColor darkMode' : 'pageColor'}`} style={styles.containerLeftPill}>
                            {
                                getLeftContainerPillContents(lightMode)
                            }
                        </div>
                        <div className={`col-sm ${lightMode ? 'pageColor darkMode' : 'pageColor'}`}>
                            <img src={trans} width='100px' height='100px'></img>
                        </div>
                        <div className={`col-sm ${lightMode ? 'pageColor darkMode' : 'pageColor'}`} style={styles.containerRightPill}>
                            {
                                getRightContainerPillContents(lightMode)
                            }
                        </div>
                    </div>
                    <div className='container'>
                        <>{getTextItem(false, 'p', 'aboutID', { paddingTop: '5%', textAlign: 'center'}, about)}</>
                        <br/>
                        <>{getTextItem(false, 'p', 'motoID', {textAlign: 'center'}, moto)}</>
                        <>{getTextItem(false, 'p', 'signatureID', {textAlign: 'center'}, "- LLG")}</>
                    </div>
                    <div className='row' style={styles.rowCenter}>
                        <img src={(lightMode ? worldLooking : worldGrid)} width='100%' height='300px'></img>
                        <>{getTextItem(false, 'h3', 'theEndID', {position:'relative', bottom:'170px'}, 'THE END')}</>
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = {
    rowCenter: {alignItems:'center', textAlign:'center'},
    containerPill: {
        borderRadius:'17px',
    },
    containerLeftPill: {
        height:'100px',
        padding:'0',
        borderTopLeftRadius:'17px',
        borderBottomLeftRadius:'17px',
    },
    containerRightPill: {
        height:'100px',
        borderTopRightRadius:'17px',
        borderBottomRightRadius:'17px',
    },
    containerRightPillImage: {
        height:'100px',
        padding:'0',
        borderTopRightRadius:'17px',
        borderBottomRightRadius:'17px',
    },
    containerLeftPillImage: {
        height:'100px',
        padding:'0',
        borderTopRightRadius:'17px',
        borderBottomRightRadius:'17px',
    },
    textColorLightMode: {
        paddingTop: "1%",
        color: "black"
    },
    textColorDarkMode: {
        paddingTop: "1%",
        color: "white"
    }
}

export default Home;