// CSS
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle'; // This fixed the issue with dropdowns?
import '../styles.css'

// Packages
import {React, useState, useEffect} from 'react';

/*
<div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="SwitchStyleContext"/>
                        </div>
*/

const Navbar = ({onToggleLightMode=null}) => {
    const [lightMode, setLightMode] = useState(localStorage.getItem('lightMode') == 'true')
    return (
        <nav class={(lightMode ? "navbar navbarColor" : "navbar navbarColor darkMode")}>
            <div class="container-fluid justify-content-start align-content-center">
                <div class="container d-flex flex-direction-row justify-content-start" style={{margin:"0px", padding: "0px"}}>
                    <a class="navbar-brand" 
                    style={(lightMode ? 
                        {
                            marginRight:"0px",
                            padding:"8px",
                            color: "#F5F5F5"
                        } : 
                        {
                            marginRight:"0px",
                            padding:"8px",
                            color:"white"
                        }
                        )} href="#">DECAY</a>
                    <label className='switch' style={{margin:"0px", padding:"8px", alignSelf:"center"}}>
                        <input type="checkbox" checked={lightMode} onChange={(event) => {
                            setLightMode(event.target.checked)
                            localStorage.setItem('lightMode', event.target.checked)
                            if(onToggleLightMode != null) {
                                onToggleLightMode(event.target.checked)
                            }
                        }}></input>
                        <span class="slider round"></span>
                    </label>
                    <a class="navbar-brand" 
                    style={(lightMode ? 
                    {
                        margin:"0px",
                        padding:"8px",
                        color: "black"
                    } : 
                    {
                        margin:"0px",
                        padding:"8px",
                        color:"#404040"
                    }
                    )} href="#">RADIATE</a>
                </div>
            </div>
        </nav>
    )
}

export default Navbar