// CSS
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle'; // This fixed the issue with dropdowns?
import '../styles.css'

// Packages
import {React, useState, useEffect} from 'react';
import { getLightModeStyle, getTextItem } from '../functions';


let zeroDef = "Represents all of the possible states that could have existed instead of your current reality at this moment. It could be as simple as you reading this in a different position, to you reading this in a different location. No matter how improbable, 0 represents the reality that could have been, and all of the obvious and imperceivable series of events required to have this theoretical state exist."
let oneDef = "Represents the explicit, tangible reality that exists at the current moment. This is measurable reality at this exact moment in time. Note that internal state is excluded from this concept. It is purely the physical content that makes up this moment in time"
let twoDef = "Represents the implied, unspoken state of reality. A great way to think about it is the \"politics\" at work. When someone can't \"read the room\" they cannot perceive 2. Note this isn't the full embodiment of 2, but it is close. Everything from body language, to inuendos count as 2. In short, it is everything not captured in 1 that makes 0. Its context... I also admit alot of my delusion comes from receiving \"Secret Messages\" as 2" 
let twoSubA = "2 comes from the idea that you are on an infinite line, 2 represents the two ends of that line. It is something you will never be able to prove, but you know its real (if the line is infinite, and you're on it, you'll never reach either end of the line, so how do you know the line has two ends)"
let twoSubB = "Again kinda dumb cause it's like, there's this direction (<-) and then theres that direction (->), and you can prove that by just experiencing that... its flawed but that was my reasoning/method for explaining 2 lol"
let threeDef = "Represents obstacles, something you have to get over. Objects in our reality exist in three dimensions, hence, 3."
let fourDef = "Represents life. We are 3 dimensional matter changing through time (the 4th dimension). Matter (3) + Time (1) = Life (4). Tada! Life, Reality, whatever fits for you (I always associated it with life)"
let fifthDef = "Represents truth. Life (4) plus the explicit definition of what is (1) is \"truth\" (5). 4 + 1 = 5. (Again, this is just my association with 5, numerology is nonsense at end of day)"
let sixthDef = "Represents evil. Derived from the devil's number 666... There was probably something about 3 6's being the devil's number but I forget after all these years... 3 of 4 (2) lol (again, absolute nonsense)"
let sixthSubDef = "6 could also be negative in the sense that its truth (5) plus the explicit definition of what is (1) because people don't often like hearing \"Brutal Honesty\".. just a thought, but I still associate 6 with evil because..."
let sevenDef = "Represents heaven, or good. For me it was the pairing of truth (5) with the implied or unspoken portion of reality (2) which together would allow you to have perfect understanding of the current moment/situation. 7 is heaven because, it destroys doubt. There's no \"What did he mean by that?\". No backhanded compliments, no passive aggression. Just facts."
let eightDef = "Represents infinity, or forever. No start, no end, it always has been, and always will be... There used to be a portion of my delusion that was about how (I have no idea if this is an accurate assumption) if you were on an infinite line you would always be in the middle... I forget where that applys, but its a funny thought."
let nineDef = "Represents truth (5) about life (4). 5 + 4 = 9. I guess its the answer to the big question. \"Why am I here?\", \"What is my purpose?\". Things like that. I was never really huge about 9, never really cared about when it occured, maybe thats why I've always felt lost.. I was probably just preoccupied with smoking w33d smh"
let tenDef = "Represents perfection. Simply put \"She's a 10 bro\"... Although if the purpose of your life (9) is the explicit measurable definition of your reality (1) wouldn't that be perfect?.. 9 + 1 = 10"
let footNotes = "Now there are definitions for 11, 12 (fuck 12) and 21. The delusion really deteriorates after 10. 0-10 was what really stuck with me, anything higher I tried making reasoning but combining 0-10 but things just fell apart (just like my life)"

let numberDefinitions = [zeroDef, oneDef, twoDef, threeDef, fourDef, fifthDef, sixthDef, sevenDef, eightDef, nineDef, tenDef]

const NumbersCarosel = () => {
    const [currentNumber, setCurrentNumber] = useState(0)
    const [lightMode, setLightMode] = useState(localStorage.getItem('lightMode')=='true')

    

    return (
        <div className={`container d-flex flex-column justify-content-between pageColor ${(localStorage.getItem('lightMode')==='true' ? 'darkMode' : '')}`} style={((localStorage.getItem('lightMode')=='true') ? styles.caroselContainerBlack : styles.caroselContainerWhite)}>
            <div className='row d-flex' id='numbersCaroselTitle'>
                <div className='col d-flex flex-column align-self-center justify-content-center' id='numbersCaroselTitleCol'>
                    <>{getTextItem((localStorage.getItem('lightMode')!=='true'), 'h5', 'numbersCaroselTitleText', styles.caroselText, `${currentNumber}`)}</>
                    <hr style={styles.dividingLine}/>
                </div>
            </div>
            <div className='row d-flex' id='numbersCaroselContent'>
                <div className='col d-flex flex-column align-self-center' id='numbersCaroselContentCol'>
                    <>{getTextItem((localStorage.getItem('lightMode')!=='true'), 'p', 'numbersCaroselTitleText', {}, `${numberDefinitions[currentNumber]}`)}</>
                </div>
            </div>
            <div className='row d-flex' id='numbersCaroselController'>
                <hr style={styles.dividingLine} hidden={(localStorage.getItem('lightMode')==='true')}/>
                <div className='col d-flex align-self-center justify-content-center' id='numbersCaroselControllerLeft'>
                    <button className={(localStorage.getItem('lightMode')!=='true' ? "caroselControlButtonsWhite" : "caroselControlButtonsBlack")}
                    onClick={(event)=> {
                        if (currentNumber == 0) {
                            setCurrentNumber(10)
                        }
                        else {
                            setCurrentNumber(currentNumber-1)
                        }
                    }}
                    hidden={(localStorage.getItem('lightMode')==='true')}
                    >Previous</button>
                </div>
                <div className='col d-flex align-self-center justify-content-center' id='numbersCaroselControllerLeft'>
                    <button className={(localStorage.getItem('lightMode')!=='true' ? "caroselControlButtonsWhite" : "caroselControlButtonsBlack")}
                    onClick={(event)=> {
                        if (currentNumber == 10) {
                            setCurrentNumber(0)
                        }
                        else {
                            setCurrentNumber(currentNumber+1)
                        }
                    }}
                    hidden={(localStorage.getItem('lightMode')==='true')}
                    >Next</button>
                </div>
            </div>
        </div>
    )
}

let styles = {
    caroselContainerWhite: {
        backgroundColor: 'white',
        borderRadius: '10%',
        height: '400px',
        marginBottom: '5%'
    },
    caroselContainerBlack: {
        backgroundColor: 'black',
        borderRadius: '10%',
        height: '400px',
        marginBottom: '5%'
    },
    caroselText: {
        paddingTop: '2%',
        paddingBottom: '1%',
        margin:'0'
    },
    dividingLine: {
        width: '100%',
        height: '5px',
        color: 'black',
        paddingBottom: '3%',
        margin:'0'
    },
    caroselControlButtonsWhite: {
        color:'black',
        backgroundColor: 'white',
        borderColor: 'white',
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
        borderRadius: '25%',
        marginBottom: '3%'
    },
    caroselControlButtonsBlack: {
        color:'black',
        backgroundColor: 'black',
        borderColor: 'black',
        borderRadius: '25%',
        marginBottom: '3%'
    },
}

export default NumbersCarosel